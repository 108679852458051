
const FAQDataOnDMethodology = [
    {
        id : 1,
        question : "Can I Hire Someone To Write My Dissertation Methodology?",
        answer : "Yes, you might use a writer from writemydissertationforme to complete your methodology. It's a dependable and reasonably priced method of consistently obtaining the grades you deserve.",
    },
    {
        id : 2,
        question : "How can the dissertation's originality be guaranteed?",
        answer : `Our powerful plagiarism detection software ensures that your dissertation is 100% original. Our expert writers start from fresh while writing each dissertation, guaranteeing that it is 100% original and up to academic requirements.`,
    },
    {
        id : 3,
        question : "How does a dissertation order procedure work?",
        answer : "It's easy to order a dissertation. We match you with a qualified writer when you provide us your topic, specifications, and deadlines. You can stay in touch with your writer during the writing process and ask for changes as needed.",
    },
    {
        id : 4,
        question : "How long will it take to complete my dissertation?",
        answer : "Your dissertation's length and complexity will determine how long it takes to finish. It usually takes a few weeks to several months. Together, we create a schedule that gets the job done by the deadline without sacrificing quality.",
    },

];

export default FAQDataOnDMethodology;

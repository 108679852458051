import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import leftSideImageOfGirl from '../../Image/Dissertation_Abstract/Abstract.jpg';
import arrowInSideBar from '../../Image/Dissertation_Literature_Review/Arrow.png';
import '../DissertationAbstract.css';
import FAQComponentOnDAbstract from '../FAQsComponentOnDPages/FAQComponentOnDAbstract';

function AbstractLastSection() {
  return (
    <>
    <section className="dissertationAbstractLastThreeQuestionAndFaqSection">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6 col-12 dissertationAbstractFourthFifthSixthAndFaqSection">
                    <h2>QUICK TIPS TO WRITE A HIGH QUALITY DISSERTATION ABSTRACT ?</h2>
                    <p>Dissertation abstract plays the role of developing initial interests among the readers about the core topics which are part of the dissertation study. It is used for enhancing the process in which dissertation topics will be properly evaluated within the summary of the project. The abstract will form the basis in which the introduction of the topic will be engaged.</p>
                    <p>Dissertation writing help tutors suggest that the abstract should highlight the purpose of the research along with the main findings and conclusion of the project. During the course of explaining the abstract content, it will also be essential to explain the research methodology which has been used within the dissertation project. The use of the right summary for the dissertation project will establish instant connections with the readers. As such, a planned approach to dissertation abstract is essential to plan the content management within project work.</p>
                    <h2>WHY IS IT IMPORTANT TO WRITE A GOOD DISSERTATION ABSTRACT ?</h2>
                    <p>Dissertation abstract is an essential component of dissertation project work. It creates confidence about the quality of the research and methodology used within the project. Dissertations make use of complex research methodologies which will have to be explained in a summarized manner within the dissertation abstract to ensure that the interest of the readers of the dissertation work will be engaged in the right manner.</p>
                    <p>An ideal Dissertation Abstract Length is supposed to be around 250-300 words. It should provide a fair idea about the thesis and research approach to the examiner and readers. Without the development of an effective dissertation abstract, it will be difficult to initiate initial opinion within the reading process. Have queries about the process in which a dissertation abstract can be tackled? Let our experts help you.</p>
                    <h2>BEST DISSERTATION WRITING SERVICES</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas </p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas </p>
                    <button className="dissertationAbstractGetDissertationHelpNow"><Link to={"/getQuote"}>GET DISSERTATION HELP NOW</Link></button>   
                </div>
                <div className="col-lg-5 col-12 dissertationAbstractWritingServicesAndChapters">
                    <h4>DISSERTATION WRITING SERVICES</h4>
                    <ul className="dissertationAbstractWritingServices">
                        <li className="mt-4"><img src={arrowInSideBar} alt="" /><a href="/" className="href ms-2"><span>Write My Dissertation</span></a></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/getQuote"} className="href ms-2"><span>Buy Dissertation</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationWritingServices"} className="href ms-2"><span>Dissertation Assignment Help</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationAbstract"} className="href ms-2"><span>Dissertation Abstract</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationWritingServices"} className="href ms-2"><span>Dissertation Help</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/getQuote"} className="href ms-2"><span>Hire Someone To Write My <span className="dissertationPara">Dissertation</span></span></NavLink></li>                
                        <li className="mb-4"><img src={arrowInSideBar} alt="" /><NavLink to={"/getQuote"} className="href ms-2"><span>Take My Online Dissertation</span></NavLink></li>                
                    </ul>
                    <img src={leftSideImageOfGirl} alt="Pay someone to write my dissertation" className="img-fluid my-5 dissertationAbstractLeftSideOfGirlImage" style={{height: "301px"}} />
                    <h4 className="mt-5">DISSERTATION CHAPTERS</h4>
                    <ul className="dissertationAbstractChapters">
                        <li className="mt-4"><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationProposal"} className="href ms-2"><span>Dissertation Proposal</span></NavLink></li>            
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationIntroduction"} className="href ms-2"><span>Dissertation Introduction</span></NavLink></li>            
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationLiteratureReview"} className="href ms-2"><span>Dissertation Literature Review</span></NavLink></li>            
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationMethodology"} className="href ms-2"><span>Dissertation Methodology</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationConclusion"} className="href ms-2"><span>Dissertation Conclusions</span></NavLink></li>            
                        <li className="mb-4"><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationBibliography"} className="href ms-2"><span>Dissertation Bibliography</span></NavLink></li>            
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <FAQComponentOnDAbstract />
    </>
  )
}

export default AbstractLastSection;
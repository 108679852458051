
const FAQDataOnDLReview = [
    {
        id : 1,
        question : "Can I Pay Someone To Write My Dissertation Literature Review?",
        answer : `Absolutely, we are able to do dissertation literature evaluations for graduate, master's, and undergraduate students. Our staff is prepared to tackle the breadth and depth necessary for "write my dissertation literature review" requests, guaranteeing an excellent, meticulously researched literature review customized to your particular academic needs.`,
    },
    {
        id : 2,
        question : "How can I send my case study / articles / additional materials to the writer?",
        answer : `Lorem ipsum dolor sit amet consectetur adipisicing elit. Non illum consequuntur deleniti architecto voluptatem, tempore officia obcaecati sequi provident ea! Tempore, repellendus! Possimus, voluptate. Ipsam voluptatibus officiis veritatis reiciendis nihil?`,
    },
    {
        id : 3,
        question : "How long should a dissertation literature review be?",
        answer : "A literature review takes up 10–20% of the length of your dissertation. The lengthier your dissertation must be, the longer the literature review section needs to be. Additionally, bear in mind that various educational institutions have varying standards regarding the dissertation's length. It is preferable to follow these guidelines and discuss word count restrictions with your lecturer.",
    },
    {
        id : 4,
        question : "Why are we the best dissertation literature review writing service?",
        answer : "For a number of factors, WriteMyDissertationForMe.com is the greatest dissertation review writing service. First, we have put together a skilled group of writers, editors, and support staff who collaborate to give students prompt help and excellent papers. Secondly, our costs are reasonable, enabling you to get superior-grade paper without breaking the bank. Thirdly, in order to facilitate direct communication between the client and the writer, we have created a user-friendly messaging system. You may keep an eye on the writer's work and get critical updates from them.",
    },
    {
        id : 5,
        question : "Which other advantages come with using Writemydissertationforme.com for help with a dissertation literature review?",
        answer : "Writemydissertationforme.com provides several extra advantages, such as round-the-clock customer service, unique and plagiarism-free papers, an infinite number of revisions, order delivery via email, a guarantee of confidentiality, timely delivery, and affordable costs. Customer satisfaction is our top priority, and we offer a dependable and trustworthy service.",
    },

];

export default FAQDataOnDLReview;

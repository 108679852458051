import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../CommonPage/FaqAndDissChaptersComponent.css';
import FAQDataOnDAbstract from '../../Data/FAQs/FAQDataOnDAbstract';

function FAQComponentOnDAbstract() {
    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    return (
        <>
            <section className="dissertationAbstractLastThreeQuestionAndFaqSection">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-12 col-12 dissertationAbstractFourthFifthSixthAndFaqSection dissertationAbstractFAQSection">
                            <h2>HAVE ANY QUESTIONS</h2>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    FAQDataOnDAbstract.map((item, i) => (
                                        <>
                                            <div className="faqParaAndHeadingDiv" key={item.id}>
                                                <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                                                    <h5>{item.question}</h5>
                                                </div>
                                                <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            <p className="dissertationAbstractReadMoreOnFaqSection"><span><NavLink to={"/faq"} className="href">Read More on FAQ</NavLink></span></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQComponentOnDAbstract;
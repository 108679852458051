import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import FAQDataOnDLReview from '../../Data/FAQs/FAQDataOnDLReview'
import '../DissertationLiteratureReview.css'

function FaqAndImage() {
    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    let NewFAQDataOnDLReview = FAQDataOnDLReview.slice(0, 3);
    return (
        <>
            <section className="frequentlyAskedQuestionsUnderLiteratureReviewPage">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-6 col-12 literatureReviewFrequentlyAskedQuestions">
                            <h2>HAVE ANY QUESTIONS</h2>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    NewFAQDataOnDLReview.map((item, i) => (
                                        <>
                                            <div className="faqParaAndHeadingDiv" key={item.id}>
                                                <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                                                    <h5>{item.question}</h5>
                                                </div>
                                                <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            <p className="literatureReviewReadMoreOnFaqButton"><span><NavLink to={"/faq"} className="href">Read More on FAQ</NavLink></span></p>
                        </div>
                        <div className="col-lg-5 col-12 literatureReviewLeftSideImageUnderFaqSection">
                            <p className="backgroundImageUnderFaqSection">Contact Expert Proof <br />Readers</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FaqAndImage;
import React, { useState } from 'react'
import FAQDataOnDAbstract from '../../Data/FAQs/FAQDataOnDAbstract'
import "../../Page/FAQsComponentOnDPages/FAQComponentOnDPages.css"

const FAQCompOnDAbstract = () => {
    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    return (
        <>
            <div className="accordion accordion-flush my-3" id="accordionFlushExample">
                {
                    FAQDataOnDAbstract.map((item, i) => (
                        <>
                            <div className="faqParaAndHeadingDiv" key={item.id}>
                                <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                                    <h5>{item.question}</h5>
                                </div>
                                <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                            </div>
                        </>
                    ))
                }
            </div>
        </>
    )
}

export default FAQCompOnDAbstract;

const FAQDataOnDBibliography = [
    {
        id : 1,
        question : "What should be included in a dissertation bibliography?",
        answer : "Yes, you can pay someone to do your capstone project. Write My Dissertation service provides professional writers who are experts in various subjects and styles. We guarantee plagiarism-free work, on-time delivery, secure payments, and no hidden fees. You can trust us to complete your capstone project perfectly.",
    },
    {
        id : 2,
        question : "How long is a dissertation bibliography?",
        answer : `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque quos eveniet, blanditiis beatae quod eaque excepturi magni voluptate commodi recusandae! Quod commodi libero iste? Obcaecati a ex dolorem vero.`,
    },
    {
        id : 3,
        question : "What qualifications do your writers have?",
        answer : "Our writers are highly qualified professionals with advanced degrees (Master's and Ph.D.) in various academic disciplines. They have extensive experience in academic writing and a deep understanding of dissertation requirements and standards.",
    },
    {
        id : 4,
        question : "Can I hire a professional writer to complete my dissertation?",
        answer : "Our powerful plagiarism detection software ensures that your dissertation is 100% original. Our expert writers start from fresh while writing each dissertation, guaranteeing that it is 100% original and up to academic requirements. ",
    },

];

export default FAQDataOnDBibliography;

import React from 'react'
import "./DissertationWritingServicesPage.css"
import DissertationWS1 from "../Image/Dissertation_Writing_Services/what_is_dissertation.webp"
import DissertationWS2 from "../Image/Dissertation_Writing_Services/importance_of_dissertation.webp"
import CarouselAndBannerImageForm from '../CommonPage/CarouselAndBannerImageForm'
import CostOfDissertation from './Home/CostOfDissertation'
import FAQCompOnDWServices from "../Page/FAQsComponentOnDPages/FAQCompOnDWServices"
import CardTestimonial from './Testimonial/CardTestimonial'
import { NavLink } from 'react-router-dom'

const DissertationWritingServicesPage = () => {
  return (
    <>
      <section className="firstSectionOfDissertationWritingServicesPageStartHere">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 firstSectionHeadingDiv">
              <h1>Dissertation services available</h1>
              <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="carouselBannerImageFormDiv">
                <CarouselAndBannerImageForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secondSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 secondSectionOfHeadingDiv">
              <h2>what is dissertation</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="secondSectionOfDissertationWritingServicesPageStartHere">
        <div className="container-fluid secondSectionDissertationWritingServicesPageFirstInnerDiv">
          <div className="row justify-content-evenly secondSectionDissertationWritingServicesPageSecondInnerDiv">
            <div className="col-lg-5 secondSectionParaDiv">
              <p>Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
            </div>
            <div className="col-lg-5 secondSectionImgDiv">
              <img src={DissertationWS1} alt="what is dissertation?" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section className="thirdSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 thirdSectionOfHeadingDiv">
              <h2>importance of dissertation</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="thirdSectionOfDissertationWritingServicesPageStartHere">
        <div className="container-fluid thirdSectionDissertationWritingServicesPageFirstInnerDiv">
          <div className="row justify-content-evenly thirdSectionDissertationWritingServicesPageSecondInnerDiv">
            <div className="col-lg-5 thirdSectionImgDiv">
              <img src={DissertationWS2} alt="importance of dissertation?" className='img-fluid' />
            </div>
            <div className="col-lg-5 thirdSectionParaDiv">
              <p>Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="fourthSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 fourthSectionHeadingDiv">
              <h2>We do complete Dissertation</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="fourthSectionOfDissertationWritingServicesPageStartHere">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <NavLink to={"/dissertationProposal"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Proposal</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <NavLink to={"/dissertationAbstract"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Abstract</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <NavLink to={"/dissertationIntroduction"}>
                <div className="dissertationServicesCard dissertationServicesCardHeightClassName">
                  <h5>Dissertation Introduction</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn learnMoreLink">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <NavLink to={"/dissertationLiteratureReview"}>
                <div className="dissertationServicesCard dissertationServicesCardHeightClassName">
                  <h5>Dissertation Literature Review</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <NavLink to={"/dissertationMethodology"}>
                <div className="dissertationServicesCard dissertationServicesCardHeightClassName">
                  <h5>Dissertation Methodology</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <NavLink to={"/dissertationConclusion"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Conclusion</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <NavLink to={"/dissertationBibliography"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Bibliography</h5>
                  <p className="dissertationServicesPara">Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <CostOfDissertation />
      <FAQCompOnDWServices />
      <section className="seventhSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 seventhSectionOfHeadingDiv">
              <h2>testimonial</h2>
            </div>
          </div>
        </div>
      </section>
      <section className='seventhSectionOfDissertationWritingServicesPageStartHere'>
        <CardTestimonial />
      </section>
    </>
  )
}

export default DissertationWritingServicesPage;

const FAQDataOnCapstoneProject = [
    {
        id : 1,
        question : "Can I pay someone to do my capstone project?",
        answer : "Yes, you can pay someone to do your capstone project. Write My Dissertation service provides professional writers who are experts in various subjects and styles. We guarantee plagiarism-free work, on-time delivery, secure payments, and no hidden fees. You can trust us to complete your capstone project perfectly.",
    },
    {
        id : 2,
        question : "How can I be sure the proper writer has been assigned to my order?",
        answer : `A capstone project is a complex task with many layers that require significant time for students to complete. However, Write My Dissertation For Me guarantees on-time delivery of your capstone projects. We can meet tight and strict deadlines without compromising quality. For faster completion, provide detailed requirements, and we can expedite your writing process.`,
    },
    {
        id : 3,
        question : "How to write an abstract for a capstone project?",
        answer : "Your abstract should be a concise summary of your capstone project, covering key elements from each section of your work. Write My Dissertation For Me specialists can assist you in writing an abstract for your capstone project. We know how to make it engaging for readers, impactful, and reflective of the critical points of your project.",
    },

];

export default FAQDataOnCapstoneProject;


const FAQDataOnDAbstract = [
    {
        id : 1,
        question : "How do I know the price?",
        answer : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non illum consequuntur deleniti architecto voluptatem, tempore officia obcaecati sequi provident ea! Tempore, repellendus! Possimus, voluptate. Ipsam voluptatibus officiis veritatis reiciendis nihil?",
    },
    {
        id : 2,
        question : "How can I be sure the proper writer has been assigned to my order?",
        answer : `Our staff of writers has a wide range of specializations. The administrator distributes the order to the writer who specializes in your field of expertise when it arrives at the processing facility.`,
    },
    {
        id : 3,
        question : "Is it safe to use the website Write My Dissertation For Me? Could my lecturer discover that I purchase papers?",
        answer : "We promise to keep all of your information completely private. We only need your personal information to get in touch with you. We never give away a customer's personal information to outside parties.",
    },

];

export default FAQDataOnDAbstract;


import React, { useState } from 'react'
import FAQDataOnDWritingServices from '../../Data/FAQs/FAQDataOnDWritingServices'
import './FAQComponentOnDPages.css'

function FaqComponent() {
  // const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null)
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <>
    <section className="faqCompSectionStartHere">
      <div className="container-fluid faqSectionFirstInnerDiv">
        <div className="row justify-content-evenly">
          <div className="col-md-12">
            <h2>FREQUENTLY ASKED QUESTIONS? (FAQ)</h2>
          </div>
          {
            FAQDataOnDWritingServices.map((item, i) => (
              <>
              <div className="col-lg-12 col-md-12 col-sm-12 faqParaAndHeadingDiv" key={item.id}>
              <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
              <h5>{item.question}</h5>
              </div>
              <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
              </div>
              </>
            ))
          }
        </div>
      </div>
    </section>
    </>
  )
}

export default FaqComponent;
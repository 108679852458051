import React, { useState } from 'react';
import "../../CommonPage/FaqAndDissChaptersComponent.css";
import '../FrequentlyAskedQuestions.css';
import FAQCompOnCProjects from './FAQCompOnCProjects';
import FAQCompOnDAbstract from './FAQCompOnDAbstract';
import FAQCompOnDBibliography from './FAQCompOnDBibliography';
import FAQCompOnDConclusion from './FAQCompOnDConclusion';
import FAQCompOnDGeneralQues from './FAQCompOnDGeneralQues';
import FAQCompOnDLReview from './FAQCompOnDLReview';
import FAQCompOnDMethodology from './FAQCompOnDMethodology';
import FAQCompOnDProposal from './FAQCompOnDProposal';
import FAQCompOnDWServices from './FAQCompOnDWServices';

function AskedQuestions() {
    const [selected, setSelected] = useState(1);
    const selectFunc = (e) => {
        setSelected(e);
    }
    return (
        <>
            <section className="faqPageSectionStartHere">
                <div className="container-fluid faqPageFirstInnerSectionStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 faqPageHeading">
                            <h2><span>QUICK ASSIST</span></h2>
                            <p>Answer to our most Frequently Asked Questions are just one click away.</p>
                        </div>
                        <hr />
                        <div className="col-md-4 col-sm-4 faq-page-heading-div">
                            <h5 onClick={() => selectFunc(1)}>General</h5>
                            <h5 onClick={() => selectFunc(2)}>Dissertation Writing Services</h5>
                            <h5 onClick={() => selectFunc(3)}>Dissertation Proposal</h5>
                            <h5 onClick={() => selectFunc(4)}>Dissertation Abstract</h5>
                            <h5 onClick={() => selectFunc(5)}>Dissertation Literature Review</h5>
                            <h5 onClick={() => selectFunc(6)}>Dissertation Methodology</h5>
                            <h5 onClick={() => selectFunc(7)}>Dissertation Conclusion</h5>
                            <h5 onClick={() => selectFunc(8)}>Dissertation Bibliography</h5>
                            <h5 onClick={() => selectFunc(9)}>Capstone Projects</h5>
                        </div>
                        <div className="col-md-8 col-sm-8 faq-page-heading-and-para-div">
                            <div className="faq-div">
                                <h3>{(selected === 1 && "General") || (selected === 2 && "Dissertation Writing Services") || (selected === 3 && "Dissertation Proposal") || (selected === 4 && "Dissertation Abstract") || (selected === 5 && "Dissertation Literature Review") || (selected === 6 && "Dissertation Methodology") || (selected === 7 && "Dissertation Conclusion") || (selected === 8 && "Dissertation Bibliography") || (selected === 9 && "Capstone Projects")}</h3>
                                {
                                    (selected === 1 && <FAQCompOnDGeneralQues />) ||
                                    (selected === 2 && <FAQCompOnDWServices />) ||
                                    (selected === 3 && <FAQCompOnDProposal />) ||
                                    (selected === 4 && <FAQCompOnDAbstract />) ||
                                    (selected === 5 && <FAQCompOnDLReview />) ||
                                    (selected === 6 && <FAQCompOnDMethodology />) ||
                                    (selected === 7 && <FAQCompOnDConclusion />) ||
                                    (selected === 8 && <FAQCompOnDBibliography />) ||
                                    (selected === 9 && <FAQCompOnCProjects />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AskedQuestions;

const FAQDataOnDWritingServices = [
    {
        id : 1,
        question : "What if I am not satisfied with the dissertation help you provide?",
        answer : "If you are not satisfied with the dissertation help we provide, our valued customers can request unlimited revisions. This means you can ask for changes until you are completely satisfied with our work. We understand the importance of meeting your expectations, and our goal is to ensure your satisfaction with our Dissertation Writing Services."
    },
    {
        id : 2,
        question : "Is there a specific dissertation specialist I can choose from?",
        answer : `Yes, you can choose a specific dissertation specialist. Select your tutor for satisfaction and effective coordination. Personalized assistance ensures confidence in saying, "Do My Dissertation," knowing you'll work with a knowledgeable specialist meeting your specific needs.`
    },
    {
        id : 3,
        question : "Do you offer reasonably priced dissertation assistance?",
        answer : "Yes, we offer reasonably priced dissertation assistance. In addition to our reasonable costs, we also provide a range of discounts to our esteemed clients to make their lives easier. Our aim is to ensure that our Dissertation Help Service remains accessible and affordable to all those in need. So, if you require assistance with your dissertation, don't hesitate to reach out to us."
    },
    {
        id : 4,
        question : "How Do I Start Making Use of Your Services?",
        answer : "To begin, contact us via email or our website. Our team provides advice and details to start your inquiry. Whether guidance, assistance, or support is needed, our Dissertation Writing Services are here for you every step of the way."
    },
    {
        id : 5,
        question : "Do you provide a refund policy?",
        answer : "Yes, we offer a refund policy. If your deadline is missed or the paper doesn't meet your requirements, contact support for a refund. We prioritize customer satisfaction in our Dissertation Assistance Service, addressing concerns to ensure a positive experience."
    },
    {
        id : 6,
        question : "How can I submit an order?",
        answer : "To submit an order, fill out a unique form with necessary details on our platform. Submit your deposit, which goes to the writer upon your satisfaction. Contact our customer care for queries. Avail our Dissertation Help service today."
    },
    {
        id : 7,
        question : "Does my data have security?",
        answer : "Yes, your data is secure. We encrypt all customer data to prevent third-party access. We safeguard against data breaches and online threats, ensuring your privacy. Trust our Dissertation Writing Service for secure handling of your information."
    },
    {
        id : 8,
        question : "Can I ask for changes to the dissertation after I've placed my order?",
        answer : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit sunt eius debitis consequatur incidunt magnam doloremque esse voluptatibus repellendus perferendis! Cumque eligendi quasi qui eveniet aperiam esse illo accusamus temporibus?"
    },
    {
        id : 9,
        question : "How do I choose the best online dissertation writer?",
        answer : "To choose the best online dissertation writer, utilize our research paper writing service. We match each project with a suitable writer based on expertise and education. For optimal results, order the full dissertation at once, ensuring continuity with the same reliable writer."
    },

];

export default FAQDataOnDWritingServices;
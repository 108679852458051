import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../CommonPage/FaqAndDissChaptersComponent.css';
import FAQDataOnDConclusion from '../../Data/FAQs/FAQDataOnDConclusion';
import arrowIcon from '../../Image/Dissertation_Literature_Review/Arrow.png';

function FAQCompOnDConclusionPage () {
    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    let NewFAQDataOnDConclusion = FAQDataOnDConclusion.slice(0, 3);
    return (
        <>
            <section className="commonComponentFaqAndDissertationChapterSection">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-7 col-12 commonComponentFaqSection">
                            <h2>HAVE ANY QUESTIONS</h2>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    NewFAQDataOnDConclusion.map((item, i) => (
                                        <>
                                            <div className="faqParaAndHeadingDiv" key={item.id}>
                                                <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                                                    <h5>{item.question}</h5>
                                                </div>
                                                <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            <p className="commonComponentReadMoreOnFaqSection"><span><NavLink to={"/faq"} className="href">Read More on FAQ</NavLink></span></p>
                        </div>
                        <div className="col-lg-5 col-10 commonComponentChapters">
                            <h4>DISSERTATION CHAPTERS</h4>
                            <ul>
                                <li className="mt-3"><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationAbstract"} className="href ms-2">Dissertation Abstract</NavLink></li>
                                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationIntroduction"} className="href ms-2">Dissertation Introduction</NavLink></li>
                                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationLiteratureReview"} className="href ms-2">Dissertation Literature Review</NavLink></li>
                                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationMethodology"} className="href ms-2">Dissertation Methodology</NavLink></li>
                                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationConclusion"} className="href ms-2">Dissertation Conclusions</NavLink></li>
                                <li className="mb-3"><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationBibliography"} className="href ms-2">Dissertation Bibliography</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQCompOnDConclusionPage;